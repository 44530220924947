<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Supplier Configuration - Mazaya</title>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet">
  
  </head>
  <div class="mazaya-wrapper">
    <header class="mb-0">
      <div class="d-flex">
        <div class="d-block flex-grow-1">
          <a (click)="goBack()"  class="d-flex align-items-center" style="text-decoration: none;">
            <i style="color: grey;" class="bi bi-arrow-left me-1"></i>
            <div style="color: grey;" class="page-back">Back</div>
        </a>
        <div id="header-logo">
            <img id="logo" src="../../../../../assets/images/mazayaPlus/logo.svg" alt="Logo">
          
        </div>
          <!-- <h1 class="title">FAQ</h1> -->
        </div>
      
     
      </div>
    </header>
    <div class="divider"></div>
  
 
  
    <div class="container mt-5">
      <h1 class="mb-4 text-center">Frequently Asked Questions</h1>
      <div  class="search-area d-flex justify-content-end">
        <input style="width: 300px;"
          type="text"
          class="form-control d-inline-block mw-50"
          [(ngModel)]="searchQuery"
          placeholder="Search by question"
          
        />
        <button class="btn btn-blue ms-2" (click)=" search()">Search</button>
        <button class="btn btn-clear ms-2" (click)="clearSearch()">Clear</button>
      </div>
  
      <div *ngFor="let item of filteredFaqs; let i = index" class="faq-item d-flex">
        <div class="faq-index">
          <strong>{{ (currentPage - 1) * faqsList.pageSize + i + 1 }}</strong>
        </div>
        <div class="faq-content flex-grow-1">
          <div class="faq-question d-flex justify-content-between align-items-center" (click)="toggleAnswer(item)">
            <div>{{ item.question }}</div>
            <div class="icon-toggle">
              <span *ngIf="!item.isVisible" class="plus-icon">+</span>
              <span *ngIf="item.isVisible" class="minus-icon">-</span>
            </div>
          </div>
          <div class="faq-answer mt-2" *ngIf="item.isVisible">
            {{ item.answer }}
          </div>

        </div>
    
        
       
         
      
      </div>
      
      <!-- Pagination -->
      <div *ngIf="faqsList" class="footer">
        <app-pagination [totalCount]="faqsList.totalCount" [currentPage]="currentPage" (changePage)="onPageChange($event)"></app-pagination>
      </div>
      
      <!-- No Data Message -->
      <div *ngIf="faqsList?.list.length === 0" class="content no no-offers">
        There is no matching data.
      </div>
    </div>
  </div>
  
  
  
  
  

