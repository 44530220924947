import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashboardModel } from '../models/dashboard/dashboard-model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private resource = `${environment.serverUrl}/dashboard`;

    constructor(private http: HttpClient) { }

    getDashboradItems(): Observable<DashboardModel[]> {
        return this.http.get<DashboardModel[]>(`${this.resource}`);
    }
}
