<ol>
    <li>INTERPRETATION
        <ol>
            <li>
                Section headings shall not affect the interpretation of this Agreement.

            </li>
            <li>
                A person includes a natural person, corporate or unincorporated body (whether or not having separate
                legal personality).
            </li>
            <li>
                The Annexures and Particulars form part of this Agreement and shall have effect as if set out in full in
                the body of this Agreement. Any reference to this Agreement includes the Annexures and Particulars.
            </li>
            <li>
                Unless the context otherwise requires, words in the singular shall include the plural and, in the
                plural, shall include the singular.

            </li>
            <li>
                This Agreement shall be binding on, and ensure to the benefit of, the Parties to this Agreement and
                their respective personal representatives, successors and permitted assigns, and references to any Party
                shall include that Party's personal representatives, successors and permitted assigns.

            </li>
            <li>
                A reference to a statute or statutory provision is a reference to it as amended, extended or re-enacted
                from time to time. A reference to a statute or statutory provision shall include all subordinate
                legislation made from time to time under that statute or statutory provision.

            </li>
            <li>
                A reference to writing or written includes email messages.
            </li>


        </ol>
    </li>
    <li>PROVISION OF SERVICES
        <ol>
            <li>
                With effect from the Effective Date and during the Term, the Service Provider shall provide the Services
                for the benefit of the Users in accordance with this Agreement.
            </li>
            <li>
                The Service Provider shall ensure that at all times the Services are provided and that the Facilities
                are maintained and operated:
                <ol>
                    <li>
                        with the exercise of the skill, care, diligence, prudence, foresight and judgement which would
                        be expected from a suitably skilled, trained and experienced person operating the Facilities;

                    </li>
                    <li>
                        to the standard that would be expected of an internationally renowned and leading operator of
                        facilities similar to the Facilities (bearing in mind any award or star system operated in
                        respect of the Facilities);

                    </li>
                    <li>
                        in compliance with all Applicable Laws;
                    </li>
                    <li>
                        in compliance with all notices received by the Service Provider from competent authorities;

                    </li>
                    <li>
                        to the highest standards with regards to health, safety and environment (“<span
                            class="span-bold">HSE</span>”) in accordance
                        with good industry practice. The Service Provider will notify the Company of all HSE incidents
                        and hazards that impact or could impact upon the Users or the Company;
                    </li>
                    <li>
                        in accordance with the Standard Terms of this Agreement and any Special Terms as outlined under
                        Annexure 1 of this Agreement; and
                    </li>
                    <li>
                        in accordance with the HSE policies and requirements of the Company provided to the Service
                        Provider (as applicable) from time to time.
                    </li>

                </ol>
            <li>
                The Service Provider shall notify the Company at least thirty (30) calendar days in advance of any
                suspension period, except where such suspension is due to a Force Majeure Event. Any suspension period
                shall be compensated by the Service Provider in an amount equivalent to the value of the lost days.
                Failure by the Service Provider to meet its obligations under this clause shall result in a penalty
                equal to ten percent (10%) of the total Fees payable under this Agreement, which shall become
                immediately due and payable by the Service Provider.

            </li>
            <li>
                MAZAYA ++ is the name & symbol used to identify and distinguish the MAZAYA ++ product/services from
                competition, all titles, character names, and other distinctive features of advertisements related to
                MAZAYA ++ are duly registered. The Service Provider shall not use MAZAYA ++ logo, branding or change the
                MAZAYA ++ branding without obtaining the prior written approval of the Company.

            </li>
            <li>
                From the Effective Date, the Company shall provide Service Provider with credentials (username(s) and
                password(s)) to access the Supplier Platform subject to its relevant Terms and Condition. In this
                regard, the Service Provider:
                <ol>
                    <li>
                        2.5.1shall use the Supplier Platform strictly to download relevant reports and/or upload special
                        offer materials in relation to the Services, provided that such materials are approved
                        beforehand by the Company;

                    </li>
                    <li>
                        2.5.2shall take all necessary steps to protect the confidentiality of the credentials
                        (username(s) and password(s));

                    </li>
                    <li>
                        shall be responsible for any action taken by any person accessing the Supplier Platform with the
                        provided credentials;

                    </li>
                    <li>
                        under no circumstances, shall the Service Provider be allowed to tamper or change the
                        credentials (username(s) and password(s)). The provided password(s) will be frequently changed
                        by the Company for security purposes; nevertheless, such measures do not relieve the Service
                        Provider from its responsibility of unauthorized use of the credentials;

                    </li>
                    <li>
                        acknowledges that the Company can alter, amend, or deactivate the Supplier Platform at any time
                        and for any reason whatsoever, including by deleting or removing any information contained on
                        the Platform and changing the credentials (username(s) and/or password(s)) provided to the
                        Service provider;

                    </li>
                    <li>
                        acknowledges that The Company hereby makes no representation and gives no warranty as to the
                        functionality, availability, use, operation, or any other aspect of the Supplier Platform; and

                    </li>
                    <li>
                        is not allowed to convey or share through any medium any of the data or rates made available by
                        the Company or other service providers at the Supplier Platform to any other channels.
                    </li>
                </ol>

            </li>
            <li>
                At all times during the course of this Agreement, the Service Provider will pay all taxes, duties and
                fees applicable to the Facilities, and obtain all permits, licenses and approvals required in order to
                operate the Facilities, provide the Services and otherwise perform its obligations under this Agreement.
            </li>
        </ol>
    </li>
    <li>REPORTING AND CONNECTIVITY
        <ol>
            <li>From the Effective Date, and for purposes of reporting, the Company shall, at its sole discretion,
                either accept to connect via the Service Provider’s Application Programming Interface, or provide the
                Service Provider with scanning device(s) (the “<span class="span-bold">Scanning Equipment</span>”) , as
                detailed under the Particulars,
                on loan bases and which must be used by the Service Provider and his personnel exclusively in keeping
                records of the Services, Users details, ticket(s) redemption, eligible entries, verification process
                etc., subject to the following terms:
                <ol>
                    <li>
                        the Service Provider undertakes to use the Scanning Equipment solely for the performance of the
                        Services, in a safe and proper manner, and in accordance with all Applicable Laws and
                        regulations.

                    </li>
                    <li>
                        the Service Provider further undertakes and warrants to the Company that the Scanning Equipment
                        shall constitute the exclusive method to be used by the Service Provider and his personnel in
                        keeping records of the Services, Users details, ticket(s) redemption, eligible entries,
                        verification process etc.

                    </li>
                    <li>
                        the Scanning Equipment is loaned to the Service Provider in "as-is" condition. The Service
                        Provider acknowledges that it has inspected the Scanning Equipment and confirmed it is in good
                        working order.

                    </li>
                    <li>
                        The Service Provider shall be responsible for maintaining the Scanning Equipment in good
                        condition and for any repair costs incurred due to damage or misuse of the Scanning Equipment
                        during the Term.

                    </li>
                    <li>
                        The Service Provider hereby assumes all risks of loss or damage to the Scanning Equipment from
                        any cause during the Term; accordingly, the Service Provider hereby agrees to indemnify the
                        Company, upon request, for any and all repair costs and all related expenses to restore the
                        Scanning Equipment to its original condition, except for normal wear and tear as determined in
                        the sole opinion of the Company.

                    </li>
                    <li>
                        daily late fees of AED 150 shall apply should the Service Provider fail to return the Equipment
                        at the expiry or early termination of this Agreement or as notified by the Company.
                    </li>

                </ol>
            </li>
            <li>
                Service Provider hereby appoints the Operations Representative, details of whom are listed under the
                Particular, to coordinate, liaise and communicate with the Company’s personnel on an on-going basis on
                day-to-day matters relevant to the performance of the Agreement and provision of Services. In this
                regard the Service Provider shall:
                <ol>
                    <li>
                        ensure that the Operations Representative is available on a reasonable basis (during its normal
                        working hours and in cases of reasonable need outside working hours) during the validity of the
                        Agreement;
                    </li>
                    <li>
                        appoint and notify the Company of the contact details of an alternate (who shall be subject to
                        this clause) who can replace the Operations Representative during any period in which the
                        Operations Restorative is unavailable; and
                    </li>
                    <li>
                        ensure that the following Operations Representative is having appropriate authority to act on
                        its behalf in regard to implementation and administration of the Agreement:
                    </li>
                </ol>
            </li>

        </ol>
    </li>
    <li>
        QUALITY CONTROL & DEFECTIVE SERVICES
        <ol>

            <li>
                In order to retain service quality assurance and quality control the Company from time-to-time reserve
                right to obtain Users feedback and advise Service Provider for improvement wherever applicable. The
                feedback acceptable (Extremely happy) threshold is set to 95%.
            </li>
            <li>
                The Company may, at its sole discretion, require the Service Provider to replace any Facilities or
                Services if the number of Users’ complaints exceeds ten (10) per week. The replacement shall be provided
                within seven (7) calendar days and must meet or exceed the current standards. Failure by the Service
                Provider to comply with this obligation shall result in a penalty equal to ten percent (10%) of the
                total Fees payable under this Agreement, which shall become immediately due and payable by the Service
                Provider.

            </li>
            <li>
                The Facilities shall remain accessible 365 days a year without any limitation to weekends, public
                holidays including Eid holidays. Subject to Clause 2.3, Facilities under maintenance condition shall not
                be for more than seven (7) calendar days.

            </li>
            <li>
                The Service Provider warrants and represents that, as of the Effective Date, all its User facing
                personnel are properly trained and fully informed regarding the Mazaya++ program and the Services.
            </li>
            <li>
                If the Company considers that the performance of all or part of the Services by the Service Provider is
                not in accordance with this Agreement, the Company may by notice to the Service Provider require the
                Service Provider to correct such defect. Furthermore, the Company, upon giving the Service Provider
                seven (7) working days’ notice, shall be entitled to conduct site visits and inspections of the
                Facilities to ensure conformity with the Services provided.
            </li>

        </ol>
    </li>
    <li>
        FEES, INVOICING AND PAYMENT
        <ol>
            <li>
                In consideration of the Company procuring that the Users use the Facilities; the Service Provider agrees
                the Fees payable for the Services will be fixed at the amount specified in Annexure 2.
            </li>
            <li>
                The Service Provider shall comply with any additional invoicing requirements of the Company as set out
                in Annexure 2.
            </li>
            <li>
                The Company is responsible for the payment of only those Services outlined in Annexure 1, or additional
                Services as requested by the Company, during eth Term, and agreed upon with the Service Provider under a
                separate addendum. Any other additional services utilized by the Users either directly or indirectly via
                their additional escorts or assistants, shall be settled between the Service Provider and the Users.

            </li>
            <li>
                In the event that the Facilities are closed and/or inaccessible to the Users, for more than seven (7)
                calendar days, the Company shall be entitled to pay the Service Provider’s invoice on a pro rata basis
                to exclude such days.

            </li>
            <li>
                The Company shall have the right, without prior notice to the Service Provider, to set off and deduct
                from any payments due or payable to the Service Provider any amounts owed by the Service Provider to the
                Company under this Agreement or any other agreement between the Parties, including but not limited to
                damages, penalties, indemnities, or refunds. The Company’s right to set off under this Clause is
                cumulative and shall be in addition to any other rights or remedies it may have under this Agreement or
                the Applicable Laws. The exercise of such set-off rights by the Company shall not constitute a waiver of
                any of its other rights or remedies.

            </li>

        </ol>
    </li>
    <li>
        TAXES
        <ol>
            <li>
                Where value added tax or similar charges in respect of transactions involving the sale or provision of
                goods and/or services and payable to any governmental authority in the United Arab Emirates (“<span
                    class="span-bold">UAE</span>”) in
                accordance with the law is applicable to any Services supplied pursuant to this Agreement (“<span
                    class="span-bold">UAE VAT</span>”),
                the Service Provider:
                <ol>
                    <li>
                        subject to compliance with Clause 6.2, is entitled to charge UAE VAT on the applicable supply at
                        the applicable rates under the Applicable Laws in relation to VAT, including “Federal Decree –
                        Law No. (8) of 2017 on Value Added Tax” as amended from time to time (the “<span
                            class="span-bold">VAT LAW</span>”);

                    </li>
                    <li>
                        shall include the UAE VAT amount as an additional line item on the applicable invoice; and

                    </li>
                    <li>
                        shall do such other things and provide such other information and documents as may be reasonably
                        required by the Company to enable the Company to claim an input credit or refund under VAT LAW.
                    </li>

                </ol>
            </li>
            <li>
                In addition to such invoicing requirements, each invoice shall be signed and stamped with the official
                stamp of the Service Provider and include:
                <ol>
                    <li>
                        the Fees payable together with any UAE VAT payable in accordance with Clauses 5 and 6 of this
                        Agreement;
                    </li>
                    <li>
                        reasons for the required payment together with supporting documentation;
                    </li>
                    <li>
                        bank details for where payments are to be made; and
                    </li>
                    <li>
                        any invoicing requirements in relation to UAE VAT as may be notified by the Company to the
                        Service Provider from time to time.
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        INDEMNITY
        <ol>
            <li>
                The Service Provider shall indemnify the Indemnitees from all Indemnifiable Costs relating to any
                failure by Service Provider to observe and perform its respective duties and obligations under this
                Agreement. Service Provider shall also indemnify Indemnitees from all Indemnifiable Costs arising from
                any claims of third parties or Compnay’s customers relating to Service Provider's failure to perform,
                any duty or obligation under this Agreement, or any agreement entered by Service Provider in connection
                with this Agreement.
            </li>
        </ol>
    </li>
    <li>
        INSURANCE
        <ol>
            <li>
                The Service Provider shall maintain such full and comprehensive insurance policies in respect of the
                Services and the Facilities (including public liability insurance) as would ordinarily be required in
                accordance with good industry practice and all Applicable Laws. The Service Provider’s compliance with
                the insurance requirements set out in this clause shall not be construed as limiting the Service
                Provider's liability to the Company and others at law or in accordance with this Agreement. </li>
        </ol>
    </li>
    <li>
        TERM AND TERMINATION
        <ol>
            <li>
                Subject to Clauses 9.2 and 9.4, this Agreement will come into effect as of the Effective Date and shall
                remain valid for one (1) Year therefrom (the “<span class="span-bold">Term</span>”). Upon the expiry of
                the Term, this Agreement shall
                be automatically renewed for additional terms of one (1) year each (the “<span
                    class="span-bold">Extended Term</span>(s)”).

            </li>
            <li>
                The Company may terminate this Agreement, for any reason, upon at least thirty (30) calendar days’
                written notice to the Service Provider.

            </li>
            <li>
                The Service Provider may terminate this Agreement by providing the Company with at least ninety (90)
                calendar days' prior written notice before the end of any Extended Term.
            </li>
            <li>
                Notwithstanding other remedies and compensation available to the Company under this Agreement or the
                Applicable Laws, the Company may terminate/suspend this Agreement immediately upon notice to the Service
                Provider, where the Service Provider:
                <ol>
                    <li>
                        becomes insolvent, bankrupt, goes into liquidation or administration or is subject to events of
                        a similar nature;

                    </li>
                    <li>
                        suspends or abandons the performance of the Services;

                    </li>
                    <li>
                        breaches a material obligation under this Agreement; and

                    </li>
                    <li>
                        acts in a way that does not align with the values of the Company or acts in a way that could
                        cause the Company or the Users any sort of harm.

                    </li>


                </ol>
            </li>
        </ol>
    </li>
    <li>
        REPRESENTATIONS AND WARRANTIES
        <ol>
            <li>
                Each Party warrants and represents to the other Party that:
                <ol>
                    <li>
                        it has duly executed and delivered this Agreement;

                    </li>
                    <li>
                        it has the full power and authority to execute, perform and observe this Agreement and its
                        authorized signatory is fully empowered and authorized to execute this Agreement on its behalf;
                    </li>
                    <li>
                        it has obtained all necessary governing body and shareholder approvals and all other necessary
                        governmental and other consents, approvals and registrations to authorize the execution,
                        performance and observance of this Agreement; and
                    </li>
                    <li>
                        the execution, performance and observance by it of this Agreement will not result in any breach
                        of its constitutional documents, or any provision contained in any agreement or instrument to
                        which it is a party or by which such company is bound or any law applicable to it.
                    </li>
                </ol>
            </li>
            <li>Each Party acknowledges that the other Party is entering into this Agreement in reliance upon each of
                the warranties in Clause 10.1.
            </li>
        </ol>
    </li>
    <li>
        CONFIDENTIALITY
        <ol>
            <li>
                The Service Provider shall not disclose to any person any information (whether confidential or
                otherwise) disclosed to it by the Company concerning the business affairs of the Company, personal
                information of the Users, this Agreement and any drafts thereof or any discussions and negotiations
                between the Parties pertaining to this Agreement. Notwithstanding the forgoing, the Service Provider
                shall be entitled to disclose the Confidential Information to: (a) directors, officers and employees, to
                the extent such persons need to know such information for the purposes of the Service Provider
                performing its obligations under this Agreement; or (b) as may be required by the Applicable Laws, court
                order or by any governmental or regulatory authority, provided that the Service Provider uses its
                reasonable commercial efforts to provide the Company with prompt notice of such requirement in order to
                enable the Company undertake appropriate measures to prevent or restrict the disclosure of such
                Confidential Information.

            </li>
            <li>
                The obligations of confidentiality contained in this Clause ‎11 shall survive the expiry or termination
                of this Agreement.
            </li>
            <li>
                Service Provider shall maintain reasonable privacy procedures for protecting personal information of the
                Users.
            </li>
        </ol>
    </li>
    <li>
        NOTICES
        <ol>
            <li>
                All notices and written communications required or permitted to be given under this Agreement shall be
                deemed to be given three (3) calendar days after transmission by electronic mail message, confirmed by
                letter, deposited in the official mail as registered or certified air mail or sent by overnight courier
                service, postage prepaid, and addressed to the other Party at the address as set forth under the
                Particulars. Either Party may at any time specify, in writing to the other Party, a different address or
                person to receive notices on its behalf.
            </li>
        </ol>
    </li>
    <li>
        INTELLECTUAL PROPERTY RIGHTS
        <ol>
            <li>
                The Service Provider acknowledges and agrees that all intellectual property rights owned by or licensed
                to the Company as at the Effective Date and any other intellectual property rights owned or acquired by
                or licensed to the Company after the Effective Date including but not limited to logos, trademarks (copy
                of MAZAYA ++ trademark is attached under Annexure 4), service marks, marketing collateral materials etc.
                (the “<span class="span-bold">Company Materials</span>”) are vested and shall remain vested in the
                Company or its licensors.

            </li>
            <li>
                The Company hereby grants, or procures the grant (as applicable), to the Service Provider a
                royalty-free, worldwide and non-exclusive license for the Term to use the Company Materials in the
                United Arab Emirates solely to the extent necessary to allow the Service Provider to perform its
                obligations under, and in accordance with, this Agreement, and in strict compliance with the guidelines
                which shall be communicated to the Service Provider from time to time. The Service Provider shall ensure
                that all MAZAYA++ marketing collateral and other Company Materials are prominently displayed in visible
                locations within the Facilities to promote User awareness of the Services.

            </li>
            <li>
                The Company acknowledges and agrees that all intellectual property rights owned by or licensed to the
                Service Provider as at the Effective Date and any other Intellectual Property Rights owned or acquired
                by or licensed to the Service Provider after the Effective Date including but not limited to logos,
                trademarks, service marks, marketing collateral materials etc. (the “<span class="span-bold">Service
                    Provider Materials</span>”) are
                vested, and shall remain vested, in the Service Provider or its licensors.
            </li>
            <li>
                The Service Provider grants to the Company a perpetual, irrevocable, royalty-free, worldwide
                transferable and sub-licensable license to use the Service Provider Materials to the extent that they
                are necessary to allow the Company to make full use of the Services.
            </li>
            <li>
                The Service Provider acknowledges that in the course of providing the Services the Service Provider may
                use products, materials and methodologies proprietary to the Company, including the Company Materials.
                The Service Provider agrees that it shall not acquire any rights in those proprietary products,
                materials and methodologies whether under this Agreement or otherwise.
            </li>

        </ol>
    </li>
    <li>
        FORCE MAJEURE
        <ol>
            <li>
                Subject to the terms of this Clause 14, neither Party shall be liable or responsible for delays or
                failures in performance under this Agreement if and to the extent such delay or failure in performance
                is caused by any occurrence beyond the reasonable control of the affected Party, including any such
                delay or failure resulting from: (a) any acts of god such as fire, earthquake, tsunami, flood, storm,
                cyclone (b) any act of war, invasion, armed conflict or act of foreign enemy, or a sustained campaign of
                terrorism or political sabotage (any such event or occurrence, a “<span class="span-bold">Force Majeure
                    Event</span>”). The Party
                affected by a Force Majeure Event shall, as soon as practically possible, notify the other Party in
                writing of the existence of such Force Majeure Event that makes it impossible for the affected Party to
                carry out in whole or in part its obligations under this Agreement. The Party affected by a Force
                Majeure Event shall take all reasonable steps to mitigate the effect of such Force Majeure Event and
                recommence the performance of the affected obligations. In the event the Force Majeure Event continues
                for more than ninety (90) calendar days, then either Party may give notice to the other party of its
                intention to terminate this Agreement.

            </li>
            <li>
                A Force Majeure Event does not relieve a Party from liability for an obligation which arose before the
                occurrence of that Force Majeure Event
            </li>
            <li>

                A Force Majeure Event does not include (i) any circumstance caused by the act, omission, negligence or
                default of a party to this Agreement, or any subconsultant, supplier or provider at any level in the
                Service Provider’s supply chain, (ii) a failure of the Service Provider to perform the Services in
                accordance with this Agreement, (iii) industrial disputes specific to the performance of the Services,
                or (iv) either party having insufficient funds to fulfil that Party's obligations under this Agreement.
            </li>
        </ol>
    </li>
    <li>
        ASSIGNMENT AND SUB-CONTRACTING
        <ol>
            <li>
                The Service Provider hereby acknowledges and accepts that the Company may assign, novate or transfer any
                of its rights or obligations under this Agreement without the prior written consent of the Service
                Provider.
            </li>
            <li>
                The Service Provider may not assign, novate or transfer any of its rights or obligations under this
                Agreement without the prior written consent of the Company.
            </li>
            <li>
                The Service Provider may subcontract any of its obligations under this Agreement with the Company's
                prior written consent, which shall not be unreasonably withheld or delayed.
            </li>
            <li>
                The Service Provider shall remain responsible for all acts and omissions of its approved subcontractors
                as fully as if they were the acts and omissions of the Service Provider or its employees or agents.
            </li>

        </ol>
    </li>
    <li>
        GOVERNING LAW AND DISPUTE JURISDICTION
        <ol>
            <li>
                16.1This Agreement shall be governed by and construed in accordance with the federal laws of the UAE to
                the extent applies in the Emirate of Abu Dhabi and the local laws of the Emirate of Abu Dhabi.

            </li>
            <li>
                16.2In the event of any dispute between the Parties arising out of or relating to this Agreement, an
                appointed manager of each Party shall try in good faith to resolve a dispute within a period of seven
                (7) calendar days, failing which, the matter shall be escalated for the resolution by the higher
                management, whereby, C-suite executives of the Parties shall within fifteen (15) calendar days of a
                written notice from one Party to the other Party (“<span class="span-bold">Dispute Notice</span>”), hold
                a meeting between the Parties
                (“<span class="span-bold">Dispute Meeting</span>”) to resolve the dispute amicably. Each Party shall
                communicate the appointment of
                representative having requisite authority to settle the dispute.

            </li>
            <li>
                Any dispute which is not resolved within thirty (30) days after the service of a Dispute Notice, whether
                or not a Dispute Meeting has been held, the Company shall have the right to choose an appropriate forum
                for dispute resolution in accordance with the provisions of Clauses 16.5 and 16.6.

            </li>
            <li>
                It is expressly agreed between the Parties that the Company may either opt for dispute resolution
                through arbitration or through the courts of the Emirate of Abu Dhabi.

            </li>
            <li>
                In case, the Company opted to refer the dispute through arbitration then, any dispute arising out of or
                in connection with this Agreement shall be finally settled under the Rules of Arbitration of the Abu
                Dhabi Commercial Conciliation and Arbitration Centre by single arbitrator appointed in accordance with
                the said Rules. In contrast, the seat will be Abu Dhabi Global Market, and the language to be used in
                the arbitral proceedings shall be English.

            </li>
            <li>
                In case, the Company opted to settle a dispute through court proceedings, then the courts of Abu Dhabi
                shall have exclusive jurisdiction to settle any dispute arising from or connected with this Agreement
                (including a dispute regarding the existence, validity or termination of this Agreement or relating to
                any contractual or other obligation arising out of or in connection with this Agreement) or the
                consequences of its nullity. The Service Provider irrevocably submits to the jurisdiction of the courts
                of Abu Dhabi and waive any objection they may have to any dispute being heard in the courts of Abu Dhabi
                on the grounds that it is not an appropriate forum (forum non conveniens). The Service Provider
                irrevocably waives any objection which it might now or hereafter have to the courts or bodies referred
                to in Clause 16 being nominated as the forum to hear and determine any suit, action or proceedings, and
                to settle any disputes, which may arise out of or in connection with this Agreement and agrees not to
                claim that any such court or body is not a convenient or appropriate forum.

            </li>
            <li>
                This Clause 16 shall survive the termination of this Agreement and in such event shall be delivered as
                an independent arbitration Agreement.
            </li>

        </ol>
    </li>
    <li>
        GENERAL
        <ol>
            <li>
                The Parties do not intend that a third party should have the right to enforce any provision of this
                Agreement.

            </li>
            <li>
                No failure or delay by a Party in exercising any right under this Agreement will operate as a waiver
                thereof nor will any single or partial exercise of any right preclude its further exercise or the
                exercise of any other right.

            </li>
            <li>
                Each of the provisions of this Agreement are severable and distinct from the others and if at any time
                one or more of such provisions is or becomes invalid, illegal or unenforceable, the validity, legality
                and enforceability of the remaining provisions of this Agreement will not in any way be affected or
                impaired thereby.

            </li>
            <li>
                It is understood and agreed to and acknowledged by the Parties hereto that this Agreement does not
                create any fiduciary relationship between them, and that nothing in this Agreement is intended to, nor
                shall it be construed to, constitute either Party a partner of the other, or to create any commercial or
                other partnership between the Parties hereto. The relationship of the Company Party to the Service
                Provider is of an independent contractor.

            </li>
            <li>
                This Agreement may only be amended with the prior written agreement of both Parties.

            </li>
            <li>
                This Agreement constitutes the entire agreement and understanding of the Parties and supersedes any
                previous agreement between them relating to the Services.

            </li>
            <li>
                This Agreement may be executed in any number of counterparts (including by electronic means) all of
                which taken together will on exchange constitute one agreement.

            </li>
            <li>
                The Service Provider may not issue any press release, make any other public statement and/or
                announcement, in each case relating to, connected with or arising out of this Agreement or the matters
                contained in this Agreement (including, without limitation, matters about any negotiations or
                discussions that are (or were) taking place between the Parties, the status, progress or content of such
                negotiations (including termination of negotiations) or that Confidential Information has been provided
                or received), without obtaining the prior written consent of the Company (such consent not to be
                unreasonably withheld, conditioned or delayed).
            </li>


        </ol>
    </li>

</ol>