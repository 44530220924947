<html lang="en">

<head>

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&amp;display=swap">
    <style>
        body {
            font-family: Roboto, sans-serif;
        }
    </style>
    <script>window.dataLayer = window.dataLayer || [];</script>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Terms and conditions for mazayaoffers.ae</title>
    <meta name="description" content="Terms and conditions for mazayaoffers.ae">
    <meta name="robots" content="noindex,follow">
    <meta property="article:published_time" content="2023-11-10T02:51:02+00:00">
    <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-V89YHJB9J5"></script>
    <script>window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-V89YHJB9J5');</script>
</head>

<body>
    <div id="video-container">

        <img src="../../../../../assets/images/backgrounds/login_background.jpg">

        <!-- <section class="container"> -->


    </div>
    <div class="wpembed-wrapper">
        <div id="header-logo" (click)="goToLogin()">
            <img id="logo" src="../../../../../assets/images/mazayaPlus/logo.svg" alt="Logo">
        </div>
        <div class="wpembed-container">

            <div class="terms-container">
                <div id="patries" class="patries">
                    <div class="patries-heading bold-header">
                        PARTIES
                    </div>
                    <ol class="ol-custom">
                        <li><span class="span-bold">NIRVANA TRAVEL & TOURISM LLC SPC</span>, a limited liability company duly incorporated and existing
                            under the applicable laws of Emirate of Abu Dhabi, United Arab Emirates, having its registered
                            office at P.O Box 41818, Abu Dhabi, UAE (the “<span class="span-bold">Company”</span>); and</li>
                        <li><span class="span-bold">The Service Provider</span> (as detailed under the Particulars of this Agreement).
                            each a “<span class="span-bold">Party</span>” and together the “<span class="span-bold">Parties</span>”.</li>
    
                    </ol>
    
                </div>
                <div id="introduction">
    
                    <div class="bold-header">
                        INTRODUCTION
                    </div>
                    <ol type="A" class="ol-custom">
                        <li>
                            The Service Provider is the operator of certain leisure facilities in the United Arab
                            Emirates (the “Facilities”).
                        </li>
                        <li>
                            The Company has been assigned with the operation and management of the MAZAYA ++ program
                            (“<span class="span-bold">MAZAYA ++</span>”).
                        </li>
                        <li>
                            The Company wishes to utilize the Facilities for the MAZAYAA ++ program members and their
                            families holding the MAZAYA ++ electronic card and having valid Membership(s) (as defined under
                            Annexure 1) (the “<span class="span-bold">Users</span>”).
                        </li>
                        <li>
                            The Service Provider has agreed to provide the services as detailed in Annexure 1 (the
                            “<span class="span-bold">Services</span>”) to the Users at the Facilities for the <span class="span-bold">fees</span> stipulated in Annexure 2 (the “Fees”),
                            and otherwise in accordance with the terms of this Agreement.
                        </li>
    
                    </ol>
    
                </div>
                <div id="agreed">
    
                    <div class="bold-header">
                        NOW THEREFORE IT IS AGREED AS FOLLOWS:
                    </div>
                    <ol class="ol-custom">
                        <li>
                            The above introduction is an integral part of the Agreement and shall be construed accordingly.
                        </li>
                        <li>
                            The Following documents together shall constitute the entire agreement entered into between the
                            Parties and each of them shall be read in following order of priority in case of any
                            inconsistency:
                            <ol class="ol-custom" type="a">
                                <li>
                                    the Particulars;
                                </li>
                                <li>
                                    the Annexures; and
                                </li>
                                <li>
                                    the Standard Terms.
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <div>
                        (documents listed at (a) to (c) shall collectively referred to as the Agreement and each of them as
                        an Agreement document).
                    </div>
                </div>
                <div id="standard-terms">
                    <div class="standard-terms-heading bold-header">
                        STANDARD TERMS
                    </div>
                    <div class="standard-terms-sub-heading">
                        DEFINITIONS
                    </div>
                    <table class="styled-table">
                        <thead>
                            <tr>
                                <th>Header 1</th>
                                <th>Header 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>“AED”</td>
                                <td>means (United Arab Emirates Dirham), the official currency of the United Arab Emirates.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Affiliates”</td>
                                <td>means a legal entity controlled by, controlling, or under direct or indirect common
                                    control of either Party. For the purpose of this definition, the term “control”
                                    (including the terms “controlled by” and “under common control with”) means the
                                    possession, directly or indirectly, of the power to direct or cause the direction of the
                                    management and policies of an entity, whether through the ownership of voting rights, by
                                    management agreement, contract or otherwise, and includes, without limitation, the
                                    legal, beneficial or equitable ownership, directly or indirectly, of fifty percent (50%)
                                    or more of the share capital (or other equity interest if not a corporation) of such
                                    entity ordinarily having voting rights.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Agreement”</td>
                                <td>means this services agreement and any particulars, annexures or standard terms attached
                                    thereto, as amended from time to time in accordance with the terms agreed upon herein
                                    this document. </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Application Programming Interface”</td>
                                <td>means a set of functions and procedures made available to the Company by the Service
                                    Provider, allowing the creation of applications that grants the Company with an access
                                    to specific features and/or data of an application, for reporting purposes and making
                                    available to the Company all the relevant records of the Services, Users details,
                                    ticket(s) redemption, eligible entries, verification process etc.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Company Materials”</td>
                                <td>shall have the meaning given to it under Clause 13.1.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Confidential Information”</td>
                                <td>means any information relating to or disclosed in the course of the performance of this
                                    Agreement, including any Company Materials, idea, tender information, design,
                                    technology, know-how, inventions, algorithms, data, process, technique, program, and
                                    related documents, work-in-progress, future development, engineering, present or future
                                    products, costs, sales, customers, profits, employees, key personnel, pricing policies,
                                    operational methods, technical processes, opportunities, markets, and other business
                                    affairs and methods and related information, , marketing, business, technical,
                                    strategic, research, operating, financial, or personnel matters, whether in oral,
                                    written, graphic or electronic form as well as trading strategies, customer lists,
                                    trading data and similar information.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Dispute Notice”</td>
                                <td>shall have the meaning given to it under Clause 16.2.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
    
                                <td>“Dispute Meeting”</td>
                                <td>shall have the meaning given to it under Clause 16.2.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Effective Date”</td>
                                <td>shall have the meaning given to it under the Particulars.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Extended Term(s)”</td>
                                <td>shall have the meaning given to it under Clause 9.1. </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>“Facilities”</td>
                                <td>shall have the meaning given to it under the Agreement’s Introduction.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Fees”
                                </td>
                                <td>
                                    shall have the meaning given to it under the Agreement’s Introduction, as further
                                    detailed under Annexure 2.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Force Majeure Event”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 14.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “HSE”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 2.2.5.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    "Indemnitee"
                                </td>
                                <td>
                                    means Company, its Affiliates and their respective legal representatives, directors,
                                    officers, employees and agents.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    "Indemnifiable Costs"
                                </td>
                                <td>
                                    means in relation to any particular matter: (i) all claims, penalties, fines, judgments
                                    or administrative actions made, imposed, rendered or taken against an Indemnitee with
                                    respect to that matter, and all settlements to resolve an Indemnitee's alleged liability
                                    for that matter; (ii) all reasonable costs and expenses (including the fees and
                                    disbursements of counsel or other advisors) incurred by an Indemnitee in assessing and
                                    defending against any alleged liability; and (iii) all losses and damages (whether
                                    direct or indirect) incurred by an Indemnitee by reason of that matter.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “MAZAYA ++”
                                </td>
                                <td>
                                    shall have the meaning given to it under the Agreement’s Introduction.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Membership(s)”
                                </td>
                                <td>
                                    means all access code(s), ticket(s), voucher(s) etc. (or any product/service of similar
                                    nature) provided by the Service Provider and made available for the Users to access the
                                    Facilities.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Scanning Equipment”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 3.1.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Supplier Platform”
                                </td>
                                <td>
                                    means <a href="http://Suppliers.mazayaoffers.ae" target="_blank">http://Suppliers.mazayaoffers.ae</a>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Services
                                </td>
                                <td>
                                    shall have the meaning given to it under the Agreement’s Introduction, as further
                                    detailed under Annexure 1.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Service Provider Materials”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 13.3.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Term”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 9.1.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “UAE”
                                </td>
                                <td>
                                    means the United Arab Emirates.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “UAE VAT”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 6.1.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “Users”
                                </td>
                                <td>
                                    shall have the meaning given to it under the Agreement’s Introduction.
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    “VAT LAW”
                                </td>
                                <td>
                                    shall have the meaning given to it under Clause 6.1.1.
                                </td>
                            </tr>
    
    
                        </tbody>
                    </table>
                </div>
             <app-standard-terms>
    
             </app-standard-terms>
              </div>

           
        </div>
    </div>
</body>

</html>

<!-- </div> -->