import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Output() changePage = new EventEmitter();
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 16;
  @Input() totalCount: number;
  totalPage: number;
  showComplexPagination: boolean;

  constructor() { }

  ngOnChanges() {
    this.initPagination();
  }

  initPagination() {
    this.totalPage = Math.ceil(this.totalCount / this.pageSize);
    this.showComplexPagination = false;
    if (this.totalPage > 1) this.showComplexPagination = true;
  }

  onLoadFirstPage() {
    if (this.currentPage != 1) {
      this.currentPage = 1;
      this.changePage.emit(this.currentPage);
    }
  }

  onLoadPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.changePage.emit(this.currentPage);
    }
  }

  onLoadNextPage() {
    if (this.currentPage < this.totalPage) {
      this.currentPage++
      this.changePage.emit(this.currentPage);
    }
  }

  onLoadLastPage() {
    if (this.currentPage != this.totalPage) {
      this.currentPage = this.totalPage;
      this.changePage.emit(this.currentPage);
    }
  }
}
