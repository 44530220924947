import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "src/app/shared/models/users/user-model";
import { CompanyRegistrationModel } from "src/app/shared/models/company-registration/company-registration.model";
import { Observable } from "rxjs";
import { MazayaTermsAndConditionsResponseModel } from "src/app/shared/models/terms-and-conditions/terms-and-conditions";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  private mazayaResource=`${environment.serverUrl}/mazayaTermsAndConditions`
  constructor(private http: HttpClient) { }

  login(payload: { username: string; password: string }) {
    const url = `${environment.serverUrl}/login`;
    
    return this.http.post<any>(url, {
      username: payload.username,
      loz: payload.password,
    });
  }

  register(user: UserModel) {
    user.loz = user.password;
    user.confirmLoz = user.confirmPassword;
    const url = `${environment.serverUrl}/registration`;
    return this.http.post<any>(url, user);
  }

  saveTradeLicence(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("image", file);
    return this.http.post<any>(
      `${environment.serverUrl}/document/upload`,
      formData
    );
  }
  refresh(payload: string) {
    const url = `${environment.serverUrl}/refresh`;
    return this.http.post<any>(url, { refreshToken: payload });
  }
  logout(): Observable<any> {
    const url = `${environment.serverUrl}/logout`;
    return this.http.post<any>(url, null);
  }
  getSupplierTermsAndCondition(): Observable<MazayaTermsAndConditionsResponseModel> {
    const url = `${this.mazayaResource}/getTermsConditionsSupplier`;
    return this.http.get<MazayaTermsAndConditionsResponseModel>(url);
  }
}
