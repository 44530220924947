<div class="offers-box">
    <div class="offers" [ngClass]="(collapse) ? 'offers-collapse':'offers-wide'">
        <div [id]="'offer-' + offer?.id" class="offer-card" *ngFor="let offer of offersPage.list">
            <div class="cover">
                <img class="img-cover" [defaultImage]="'assets/images/default/img_def.svg'"
                    lazyLoad="{{offer?.mainImage | img}}" [offset]="imageLoadOffset$ | async"
                    [errorImage]="'assets/images/default/offer_processed.svg'" (click)="onItem(offer.id)">
                <div class="report-flag" *ngIf="offer.reportCount > 0 && statusVisible">
                    <span class="report-flag-number">{{ offer.reportCount }}</span>
                    <div class="report-flag-icon"></div>
                </div>
                <div class="status" *ngIf="statusVisible">
                    {{ offer.status}} - {{offer.createdOn | date: 'dd.MM.yyyy.' }}
                </div>
                <div class="checked" *ngIf="checkerActive && checked(offer.id)"></div>
            </div>
            <div class="data">
                <div class="sub-title">
                    <div class="category" [routerLink]="'/offers/categories/' + offer?.categories[0]?.id">
                        {{offer?.categories[0]?.title | defaultValue: 'No category'}}</div>
                    <div class="location" *ngIf="offer?.locations[0]?.defaultAreaId">{{offer?.locations[0].defaultAreaId
                        | area}}</div>
                </div>
                <div class="company-name" (click)="onItem(offer.id)"> {{offer?.brand || offer?.companyNameEnglish }}
                </div>
                <div class="title" *ngIf="offer?.companyNameEnglish != offer?.title" (click)="onItem(offer.id)">
                    {{offer?.title | defaultValue: 'The title is not defined'}}</div>

                <div class="price">
                    <ng-container *ngIf="offer.type == 'Price'">
                        <span class="bold">AED {{offer.priceFrom | number: '1.0' }} - AED {{offer.priceTo | number:
                            '1.0'}}</span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'Discount'">
                        <span *ngIf="offer.discountFrom" class="bold">From {{offer.discountFrom}}% - To
                            {{offer.discountTo}}% Discount</span>
                        <span *ngIf="offer.discount" class="discount">{{offer.discount}}% OFF </span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'Other'">
                        <span class="bold">{{offer.priceCustom}}</span>
                    </ng-container>

                    <ng-container *ngIf="offer.type == 'DiscountPrice'">
                        <ng-container *ngIf="offer.originalPrice && offer.discountedPrice">
                            <span class="line-through">AED {{offer.originalPrice | number: '1.0'}}</span> <span
                                class="bold">AED {{offer.discountedPrice | number: '1.0'}}</span>
                        </ng-container>
                        <ng-container *ngIf="!(offer.originalPrice && offer.discountedPrice)">
                            <span class="bold">AED {{offer.discountedPrice | number: '1.0'}}</span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <div class="tag" *ngIf="offer?.tags[0]">
                {{ offer.tags[0].title }}
            </div>
        </div>
    </div>
</div>