import { Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/core/services';

@Component({
  selector: 'app-termsand-conditions',
  templateUrl: './termsand-conditions.component.html',
  styleUrls: ['./termsand-conditions.component.scss']
})
export class TermsandConditionsComponent {
  buyerTermsContent: SafeHtml;
  constructor(
    private router: Router,
    private loginService: LoginService,
 
  ) { }
  ngOnInit(): void {
    this.buyerTerms();
  }
goToLogin(){
  this.router.navigate(['/login']);
}
buyerTerms(){
  this.loginService.getSupplierTermsAndCondition().subscribe((res: any) => {
    console.log("ddd", res.data)
    this.buyerTermsContent = res.data[0].content;
  });
}
}
