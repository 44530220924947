import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompanyModel } from '../models/companies/company-model';
import { Observable } from 'rxjs/internal/Observable';
import { LocationModel } from '../models/other/location/location-model';
import { QueryModel } from '../models/query/queryModel';
import { PaginationModel } from '../models/pagination/pagination-model';
import { CompanyDetailsModel } from '../models/companies/company-details-model';
import { CompanyRegistrationModel } from '../models/company-registration/company-registration.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private resource = `${environment.serverUrl}/companies`;

  constructor(private http: HttpClient) { }

  getCompaniesP(queryModel: QueryModel): Observable<PaginationModel<CompanyModel>> {
    const url = `${this.resource}/all/page/${queryModel.page}`;
    return this.http.post<any>(url, queryModel);
  }

  getCompanies(): Observable<CompanyModel[]> {
    return this.http.get<CompanyModel[]>(`${this.resource}/all`)
  }

  getCompany(id: number): Observable<CompanyDetailsModel> {
    const url = `${this.resource}/${id}`;
    return this.http.get<CompanyDetailsModel>(url);
  }

  getCompanyBySupplier(): Observable<CompanyDetailsModel> {
    const url = `${this.resource}/getCompanyByUser`;
    return this.http.get<CompanyDetailsModel>(url);
  }

  getAdministrator(id): Observable<string> {
    const url = `${environment.serverUrl}/administration/get-supplier-admin-for-company/${id}`;
    return this.http.get<string>(url);
  }


  getCompanyLocations(id: string): Observable<LocationModel[]> {
    return this.http.post<LocationModel[]>(`${environment.serverUrl}/companylocation`, { supplierId: id })
  }

  createCompany(company: CompanyDetailsModel): Observable<any> {
    company.website = btoa(unescape(encodeURIComponent(company.website)));
    company.facebook = btoa(unescape(encodeURIComponent(company.facebook)));
    company.instagram = btoa(unescape(encodeURIComponent(company.instagram)));
    company.twitter = btoa(unescape(encodeURIComponent(company.twitter)));
    company.legalForm = btoa(unescape(encodeURIComponent(company.legalForm)));
    return this.http.post<any>(this.resource, company);
  }

  deleteCompany(id: number) {
    const url = `${this.resource}/${id}`;
    return this.http.delete<any>(url);
  }

  getOffersForCompany(id: number) {
    const url = `${this.resource}/${id}/offers`;
    return this.http.get<any>(url);
  }

  hardDeleteOfCompany(id: number) {
    return this.http.delete<any>(`${this.resource}/remove/${id}`);
  }
  registerCompany(company: CompanyRegistrationModel): Observable<any> {
    const url = `${this.resource}/update-trade-licence`;
    return this.http.post<any>(url, company);
  }
}
