import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyRegistrationLayoutComponent } from './company-registration-layout/company-registration-layout.component';
import { AuthGuard } from './core/guards/auth-guard';
import { HomeComponent } from './features/home/pages';
import { CompanyRegisterMainComponent } from './features/login/pages/company-register-main/company-register-main.component';
import { InfoComponent } from './features/login/pages/info/info.component';
import { PrivacyPolicyComponent } from './features/login/pages/privacy-policy/privacy-policy.component';
import { TermsandConditionsComponent } from './features/login/pages/termsand-conditions/termsand-conditions.component';
import { RefundPoliciesComponent } from './features/login/pages/refund-policies/refund-policies.component';
import { MazayaSuppilerTermsComponent } from './features/login/pages/mazaya-suppiler-terms/mazaya-suppiler-terms.component';

const routes: Routes = [


  {
    path: 'registration',
    component: CompanyRegistrationLayoutComponent,
    children: [
      {
        path: 'company-trade-license-update',
        component: CompanyRegisterMainComponent
      }, {
        path: "custom-registration-info",
        component: InfoComponent,
      },
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'mazaya-suppilerterms',
    component: MazayaSuppilerTermsComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsandConditionsComponent
  },
  {
    path: 'refund-policy',
    component: RefundPoliciesComponent
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'login', loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule), data: { title: 'Login' } },
      // { path : 'registration', loadChildren : () => import('./features/register/register.module').then(m => m.RegisterModule), data: { title: 'Registration' }},
      { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard], data: { title: 'Home' } },
      { path: 'offers', loadChildren: () => import('./features/offers/offers.module').then(m => m.OffersModule), canActivate: [AuthGuard], data: { title: 'Offers' } },
      { path: 'suppliers', loadChildren: () => import('./features/suppliers/suppliers.module').then(m => m.SuppliersModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'AdnocCoordinator'], title: 'Suppliers' } },
      // TODO: treba obrisati
      { path: 'companies', loadChildren: () => import('./features/companies/companies.module').then(m => m.CompaniesModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin'], title: 'Companies' } },
      { path: 'categories', loadChildren: () => import('./features/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'Buyer', 'AdnocCoordinator'], title: 'Categories' } },
      { path: 'collections', loadChildren: () => import('./features/collections/collections.module').then(m => m.CollectionsModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'Buyer', 'AdnocCoordinator'], title: 'Collections' } },
      { path: 'roadshows-administration', loadChildren: () => import('./features/roadshows-administration/roadshow.module').then(m => m.RoadshowModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'AdnocCoordinator', 'Supplier', 'SupplierAdmin', 'RoadshowFocalPoint'], title: 'Roadshow' } },
      { path: 'roadshows', loadChildren: () => import('./features/roadshows/roadshows.module').then(m => m.RoadshowsModule), canActivate: [AuthGuard], data: { expectedRoles: ['Buyer'], title: 'Roadshow' } },
      { path: 'administration', loadChildren: () => import('./features/administration/administration.module').then(m => m.AdministrationModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'AdnocCoordinator'], title: 'Administration' } },
        // TODO: treba obrisati
      { path: 'users', loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin'], title: 'Users' } },
      { path: 'focal-points', loadChildren: () => import('./features/focal-points/focal-points.module').then(m => m.FocalPointsModule), canActivate: [AuthGuard], data: { expectedRoles: ['Admin', 'AdnocCoordinator', 'SupplierAdmin'], title: 'Focal Points' } },
      { path: 'profile', loadChildren: () => import('./features/supplier-profile/supplier-profile.module').then(m => m.SupplierProfileModule), canActivate: [AuthGuard], data: { title: 'Profile' } },
      { path: 'my-profile', loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard], data: { title: 'Profile' } },
      { path: 'surveys', loadChildren: () => import('./features/survey/survey.module').then(m => m.SurveyModule), canActivate: [AuthGuard], data: { title: 'Surveys' } },
      { path: 'suggestions', loadChildren: () => import('./features/suggestions/suggestions.module').then(m => m.SuggestionsModule), canActivate: [AuthGuard], data: { title: 'Suggestions' } },
      // { path: 'company-trade-license-update', component: CompanyRegisterMainComponent },
      { path: 'mazaya-plus', loadChildren: () => import('./features/mazaya-plus/mazaya-plus.module').then(m => m.MazayaPlusModule), canActivate: [AuthGuard], data: { expectedRoles: ['Buyer'], title: 'Mazaya++' } },
      { path: 'my-mazaya-plus', loadChildren: () => import('./features/my-mazaya-plus/my-mazaya-plus.module').then(m => m.MyMazayaPlusModule), canActivate: [AuthGuard], data: { expectedRoles: ['Buyer'], title: 'my Mazaya++' } },
      { path: 'reports', loadChildren: () => import('./features/supplier-reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard], data: { title: 'Reports' } },
      { path: '**', redirectTo: '' }
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
