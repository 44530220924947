import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable, ElementRef } from '@angular/core';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import 'rxjs/add/operator/do'
import { LoaderService } from '../services/loader.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    public _notificationService: NotificationsService,
    private _loaderService: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loaderService.activeLoader(request.url);
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          this._loaderService.deactiveLoader(request.url);
          const errorMessage = this.createErrorMessage(error)
          if (typeof errorMessage === "string") {
            this._notificationService.error('Error', errorMessage);
          }
          return throwError(errorMessage);
        })
      )
      .do(event => {
        if (event instanceof HttpResponse) {
          this._loaderService.deactiveLoader(request.url);
        }
      })

  }

  private createErrorMessage(error: HttpErrorResponse): string {
    const errorContentDef = "There was an error. Please contact support desk.";
    const errorContent = error.error?.Message || error.error;
    const isHtmlErrorMessage = this.isHtmlError(errorContent);

    if (isHtmlErrorMessage) {
      return errorContentDef;
    }

    return errorContent;
  }

  private isHtmlError(errorContent: string) {
    const regexIsHtml = /<html/;
    return regexIsHtml.test(errorContent);
  }
}




