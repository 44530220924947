<form class="license-agreement-container" [formGroup]="form" autocomplete="off">
  <div class="box">
    <div class="cancel-button" (click)="previousButton()">
      <div class="cancel-button-arrow"></div>
      <span>Back</span>
    </div>
    <div class="logo"></div>
    <p>
      By clicking “I Agree”, you (the “Offeror”) hereby confirm to Nirvana Travel & Tourism LLC,
      your acceptance of the following terms:

    </p>
    <ul>
      <li>
        The Offer is extended to all persons who present a copy of their electronic or physical Mazaya Offers Card (the
        “Beneficiary(ies)”).
      </li>
      <li>
        Offeror shall directly charge the relevant Beneficiary claiming the benefit of the Offer, for the goods and/or
        services rendered to that Beneficiary.
      </li>
      <li>
        Offeror shall continue to provide quality customer and after-sales services to all Beneficiaries claiming the
        benefit of the Offer.
      </li>
      <li>
        Either Offeror or Nirvana Travel & Tourism LLC may cancel or terminate the Offer by giving the other at least
        three (3) months’ written notice of cancellation or termination (as applicable).
      </li>
      <li>
        Nirvana Travel & Tourism LLC reserves the right, at any time, to amend, update and/or supplement the criteria
        relating to the eligibility of Beneficiaries to participate in the Mazaya Offers program, by giving written
        notice to Offeror. Such notice may be communicated to Offeror by e-mail.
      </li>
      <li>
        The Offer is without commitment or liability (of whatsoever nature and howsoever described and/or arising) on
        the part of Nirvana Travel & Tourism LLC.
      </li>
      <li>
        Nirvana Travel & Tourism LLC acknowledges that the Offer is non-transferable and cannot be redeemed for cash.
      </li>
      <li>
        If a director, officer, or sponsor of the Offeror (i) is an employee of the Nirvana Travel & Tourism LLC; or
        (ii) has another interest or duty which potentially conflicts with interests of the Nirvana Travel & Tourism LLC
        (e.g. by virtue of a family member of the director, officer or sponsor of the Offeror being an employee of the
        Nirvana Travel & Tourism LLC), the Offeror hereby confirms that any such potential conflict of interests has
        been declared in accordance with Nirvana Travel & Tourism LLC’s conflict of interests policy (if any), as
        applicable.
      </li>
      <li>
        The Offer is accepted by Nirvana Travel & Tourism LLC on a non-exclusive basis and Nirvana Travel & Tourism LLC
        shall be entitled to accept other benefits from other vendors or persons at its sole and absolute discretion.
      </li>
      <li>
        Nirvana Travel & Tourism LLC shall bear no liability in relation to any dispute or claim arising out of the
        Offer.
      </li>
      <li>
        Nirvana Travel & Tourism LLC shall not have any liability to the Beneficiary, Offeror, or partner in connection
        with the Offer, including but not limited to, the product/ service delivery timing and/or quality.
      </li>
      <li>
        The Offeror is prohibited from using the Mazaya Logo, in any way, without the prior written consent of Nirvana
        Travel & Tourism LLC.
      </li>
      <li>
        The Offeror undertakes that it will not, without the prior written consent of Nirvana Travel & Tourism LLC, make
        any public announcement in respect of the Offer, including that the Offer is (or was) under consideration, or
        the status, progress, or content of any discussions with respect to the Offer.
      </li>
      <li>
        The Offeror is solely responsible to ensure that all its relevant personnel is aware of the Mazaya Offer
        provided and the mechanism of redeeming such Offer, including but not limited to ensuring that all branches (if
        applicable) are aware of the Offer and the mechanism of redeeming such Offer.
      </li>
      <li>
        The Offeror may change the Offer particulars via his Mazaya Platform account, subject to Nirvana Travel &
        Tourism LLC’s approval via the same Platform.
      </li>
      <li>
        Nirvana Travel & Tourism LLC reserves the right to approve or decline the Offer or changes to the Offer.
      </li>
      <li>
        You may not transfer your rights or your obligations under these Terms and Conditions to another person or third
        party unless Nirvana Travel & Tourism LLC’s written consent is provided.
      </li>
      <li>
        These terms shall be governed by the laws of the Emirate of Abu Dhabi and the courts of the Emirate of Abu Dhabi
        shall have exclusive jurisdiction to settle any dispute arising in connection thereof and for such purposes both
        Nirvana Travel & Tourism LLC and the Offeror irrevocably submit to the jurisdiction of the courts of the Emirate
        of Abu Dhabi.
      </li>




    </ul>
    <div class="terms-agreement">
      <input type="checkbox" id="termsAndConditions" formControlName="termsAndConditions"
        (change)="termsAndConditions()" />
      <label for="termsAndConditions">I Agree to the Terms and Conditions</label>
    </div>
    <span style="font-size: 12px; color: red;" *ngIf="showError">Please accept the terms and conditions</span>
    <div class="button-wrapper" (click)="onRegister()">
      <button>Complete</button>
    </div>
  </div>
</form>