<div class="login-info-container">
    <div class="box">
        <div class="header">
            <div class="logo-mazaya"></div>
        </div>
        <div class="content">
            <div class="text">
                <p>
                    Dear customer,
                </p>
                <p>
                    Your company is currently being processed. As soon as the processing is done, we will inform you via
                    email.
                </p>
                <p>
                    Thank you for your patience.
                </p>
                <p>
                    For more information, please contact <b>support@mazayaoffers.ae</b>.
                </p>

            </div>

        </div>
        <div class="footer">
            <div class="back" (click)="goBack()">Go Back</div>
        </div>
    </div>
</div>