import { AfterViewInit, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CartModel } from 'src/app/shared/models/mazaya-plus/cart-model';
import { Subcategory } from 'src/app/shared/models/mazaya-plus/subcategory-model';

@Component({
  selector: 'app-add-cart',
  templateUrl: './add-cart.component.html',
  styleUrls: ['./add-cart.component.scss']
})
export class AddCartComponent {

  notificationModal: boolean;

  @Output() onClose = new EventEmitter();

  cartItemData: CartModel = null;
  totalAmount: number = 0;
  totalVat: number = 0;
  grandTotalAmount: number = 0;
  cartCount: Number = 0;

  constructor(
    private _router: Router,

  ) { }

  ngOnInit() {


  }

  getCartItems() {
    const storedData = localStorage.getItem('cart');
    if (storedData) {
      const data = JSON.parse(storedData);
      this.cartItemData = data

      this.amountCalculation();
    }
    console.log("!!!!!!!!!!!!", this.cartItemData)

    this.notificationModal = true

  }

  deleteSubCategory(i:number){
    
    this.cartItemData.subCategory.splice(i, 1);
   console.log("this.cartItemData",this.cartItemData)
   if( this.cartItemData.subCategory.length==0){
    localStorage.removeItem('cart');
    this.onClose.emit();

   }else{
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
    this.amountCalculation();
   }
   
  }
  amountCalculation() {
    if (this.cartItemData.subCategory.length == 2) {
      this.totalVat = this.cartItemData.subCategory[0].vat + (this.cartItemData.subCategory[1].vat * this.cartItemData.subCategory[1].count)
      this.totalAmount = this.cartItemData.subCategory[0].amount + (this.cartItemData.subCategory[1].amount * this.cartItemData.subCategory[1].count)
      this.grandTotalAmount = this.totalAmount + this.totalVat
    } else {
      this.totalVat = this.cartItemData.subCategory[0].vat * this.cartItemData.subCategory[0].count
      this.totalAmount = this.cartItemData.subCategory[0].amount * this.cartItemData.subCategory[0].count
      this.grandTotalAmount = this.totalAmount + this.totalVat
    }
  }

  onIncrement() {

    if (this.cartItemData.subCategory.length == 2)
      this.cartItemData.subCategory[1].count++
    else
      this.cartItemData.subCategory[0].count++
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
    this.amountCalculation()
  }
  onDecrement() {
    if (this.cartItemData.subCategory.length == 2) {
      if (this.cartItemData.subCategory[1].count !== 0) {
        this.cartItemData.subCategory[1].count--
        localStorage.setItem('cart', JSON.stringify(this.cartItemData));
        this.amountCalculation()
      }
    } else {
      if (this.cartItemData.subCategory[0].count !== 0) {
        this.cartItemData.subCategory[0].count--
        localStorage.setItem('cart', JSON.stringify(this.cartItemData));
        this.amountCalculation()
      }
    }
  }

  onCloseButton() {
    this.onClose.emit();
  }
  checkout() {
    this.notificationModal = !this.notificationModal;
    this.handleLocalStorage()
    this._router.navigate(["/mazaya-plus/payment-details"]);
  }
  addBreakLine(name: string): string {
    return name.replace(/ /g, '<br/>')
  }
  deleteCart() {
    this.cartItemData = null
    localStorage.removeItem('cart');
    this.onClose.emit();
    this._router.navigate(["/mazaya-plus"]);
  }

  handleLocalStorage() {
    localStorage.setItem('cart', null);
    localStorage.setItem('cart', JSON.stringify(this.cartItemData));
  }

}
