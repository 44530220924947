<div class="mazaya-cart">
    <div class="mazaya-cart-btn" (click)="getCartItems() "
        (clickOutside)="notificationModal = false"
        >
        <div class="mazaya-cart" *ngIf="notificationModal">
            <div class="sidebar">
                <div class="check-header-card">
                  <h4>My Cart</h4>
                  <img (click)="notificationModal = false" class="arrow-card" src="../../../assets/images/icons/arrowRight_navbar_subbar_icon.svg" alt="" srcset="">
                </div>
            
                <div class="container-cart" *ngIf="cartItemData!=null">
                  <div class="top-cart">
                    <div class="sidebar-card" *ngFor="let cartItem of cartItemData.subCategory; let i=index;">
                      <div class="side-overlay">
                        <img class="side-user" src="../../../assets/images/mazayaPlus/iconCarrier-single.svg" alt="user-sidebar">
                      </div>
                      <div class="card-content">
                        <div class="check-header">
                          <div class="title" >{{cartItem.name}}</div>
                          <div class="price-sidebar">{{cartItem.currency}}<sub class="suber-text">{{cartItem.amount}}</sub></div>
                        </div>
              
                        <div class="check-header" >
                          <div class="para-sidebar" >
                            {{cartItem.description}}
                          </div>
                
                          
                          <div class="spinner-section-second" *ngIf="cartItem.name!='FAMILY MEMBERSHIP'">
                            <div class="img-user"><img src="../../../assets/images/mazayaPlus/user-filled.svg" alt="user"></div>
                            <div class="img-spinner" (click)="onDecrement()"><img src="../../../assets/images/mazayaPlus/minus.svg" alt="minu"></div>
                            <div>{{cartItem.count}}</div>
                            <div class="img-spinner-add" (click)="onIncrement()"><img src="../../../assets/images/mazayaPlus/add.svg" alt="plus"></div>
                            <div class="delete-section"  (click)="deleteSubCategory(i)">
                              <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                            </div>
                          </div>
                          <div class="delete-section" *ngIf="cartItem.name=='FAMILY MEMBERSHIP'"  (click)="deleteCart()">
                            <img class="check-delete" src="../../../assets/images/mazayaPlus/iconDelete.svg" alt="side-delete">
                          </div>
                        </div>
              
                      </div>
                    </div>
                   
                    
                  </div>
                  <div class="bottom-cart" *ngIf="cartItemData.subCategory.length>0">
                    <div class="payment-sidebar">
                      <div class="payment-text">
                        <div>Total</div>
                        <div>{{cartItemData.subCategory[0].currency}} {{totalAmount}}</div>
                      </div>
                      <div class="payment-text">
                        <div>Vat</div>
                        <div>{{cartItemData.subCategory[0].currency}} {{totalVat}}</div>
                      </div>
                      <div class="divider-checkout"></div>
                      <div class="payment-total">
                        <div>Grand Total</div>
                        <div>{{cartItemData.subCategory[0].currency}} {{grandTotalAmount}}</div>
                      </div>
                
                    </div>
                    <button class="price-btn" (click)="checkout()">Checkout</button>
                  </div>
                </div>
              
              </div>
        </div>
    </div>
</div>