import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { HomeService } from './services';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxTextOverflowClampModule } from 'ngx-text-overflow-clamp';
import {
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from 'ng-lazyload-image';
import { HomeAdminComponent } from './pages/home/home-admin/home-admin.component';
import { HomeSupplierComponent } from './pages/home/home-supplier/home-supplier.component';
import { HomeReviewerComponent } from './pages/home/home-reviewer/home-reviewer.component';
import { HomeBuyerComponent } from './pages/home/home-buyer/home-buyer.component';
import { HomeSupplierAdminComponent } from './pages/home/home-supplier-admin/home-supplier-admin.component';
import { HomeBannerComponent } from './pages/home/home-buyer/home-banner/home-banner.component';
import { HomeCollectionsComponent } from './pages/home/home-buyer/home-collections/home-collections.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeAdminComponent,
    HomeSupplierComponent,
    HomeReviewerComponent,
    HomeBuyerComponent,
    HomeSupplierAdminComponent,
    HomeBannerComponent,
    HomeCollectionsComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    NgxTextOverflowClampModule,
  ],
  providers: [
    HomeService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  ],
})
export class HomeModule { }
