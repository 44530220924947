<div class="footer-container">
    <div class="footer">
        <div class="content">
            <!-- <a [href]="url" target="_blank">Terms and Conditions</a>
            <span class="separator" style="margin-left: 10px; color:#0000ee">|</span> -->
            <a  [href]="urlFaq" target="_blank" style="margin-left: 10px;  color:#0000ee">FAQ</a>
        </div>
    </div>
</div>

<app-terms-modal *ngIf="modalTerms" (closeTermsModalE)="toggleModalTerms()"></app-terms-modal>