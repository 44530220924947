import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mazaya-suppiler-terms',
  templateUrl: './mazaya-suppiler-terms.component.html',
  styleUrls: ['./mazaya-suppiler-terms.component.scss']
})
export class MazayaSuppilerTermsComponent {

  constructor(
    private router: Router,

  ) { }

goToLogin(){
  this.router.navigate(['/login']);
}
}
