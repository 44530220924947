import { Component } from '@angular/core';

@Component({
  selector: 'app-standard-terms',
  templateUrl: './standard-terms.component.html',
  styleUrls: ['./standard-terms.component.scss']
})
export class StandardTermsComponent {

}
